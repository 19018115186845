import React from "react"
import LockIcon from "@material-ui/icons/Lock"
import styled from "styled-components"

const SecureTransactionWrapper = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: #6c6e71;
  margin-top: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  svg {
    width: 16px;
    margin-right: 6px;
  }
`

export const SecureTransaction = () => (
  <SecureTransactionWrapper>
    <LockIcon />
    Secure Transaction
  </SecureTransactionWrapper>
)
