import PropTypes from "prop-types"
import React, { useState } from "react"
import styled from "styled-components"
import CloseIcon from "@material-ui/icons/Close"
import { inject, observer } from "mobx-react"

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => (props.lg ? "50px" : "25px")};
  height: ${props => (props.lg ? "50px" : "25px")};
  font-size: ${props => (props.lg ? "18px" : "14px")};
  font-weight: 600;
  cursor: pointer;
`
const RightButton = styled(Button)`
  border: 0;
  background: #f1f4f5;
`

const LeftButton = styled(Button)`
  border: 0;
  background: #f1f4f5;
`

const CartItemWrapper = styled.div`
  width: 100%;
  position: relative;
  height: 150px;
  display: flex;
`

const CartItemImage = styled.div`
  width: 100%;
  max-width: 200px;
  height: 150px;
  background-image: url(${props => (props.imgSrc ? props.imgSrc : "")});
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
`
const CartItemContent = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 9fr 1fr;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  align-items: center;
`

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 16px;
  > a {
    font-size: 12px;
    color: #e43511;
    margin-bottom: 0;
  }
`

const Title = styled.h2`
  font-size: 10px;
  font-weight: 700;
  color: #374048;
  line-height: 20px;
`

const SubTitle = styled.h4`
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  color: #989595;
  line-height: 18px;
  margin-bottom: 0;
`

const Price = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
`

const FlexItem = styled.div`
  display: flex;
  align-items: center;
`
export const GroupedButtons = ({
  lg,
  qty = 1,
  item,
  getCount = () => {},
  handleQtyUpdate = () => {},
  handleDelete,
}) => {
  const [counter, setCounter] = useState(qty)

  function handleIncrement() {
    setCounter(counter + 1)
    getCount(counter + 1)
    if (item) handleQtyUpdate(item, { qty: counter + 1, color: item.color })
  }

  function handleDecrement() {
    if (counter > 0) {
      if (counter === 1 && handleDelete) {
        handleDelete(item)
        return
      }
      setCounter(counter - 1)
      getCount(counter - 1)
      if (item) handleQtyUpdate(item, { qty: counter - 1, color: item.color })
    }
  }

  return (
    <FlexItem>
      <LeftButton onClick={handleDecrement} lg={lg}>
        -
      </LeftButton>
      <Button lg={lg}>{counter}</Button>
      <RightButton onClick={handleIncrement} lg={lg}>
        +
      </RightButton>
    </FlexItem>
  )
}

const CartItem = ({ item, readOnly, cart: cartStore }) => {
  const handleQtyUpdate = (product, options) => {
    cartStore.updateItemQty(product, options)
  }

  const handleDelete = item => {
    cartStore.removeItem(item)
  }
  return (
    <CartItemWrapper>
      <CartItemContent>
        <FlexItem>
          <CartItemImage imgSrc={item.imgUrl} />
          <TitleContainer>
            <SubTitle>{item.color}</SubTitle>
            <Title>{item.name}</Title>
            <FlexItem style={{ marginTop: "6px" }}>
              {readOnly ? (
                <Price>qty: {item.qty}</Price>
              ) : (
                <GroupedButtons
                  item={item}
                  qty={item.qty}
                  handleQtyUpdate={handleQtyUpdate}
                  handleDelete={handleDelete}
                />
              )}
              <Price>x {item.displayPrice}</Price>
            </FlexItem>
          </TitleContainer>
        </FlexItem>
        {!readOnly && <CloseIcon onClick={() => handleDelete(item)} />}
      </CartItemContent>
    </CartItemWrapper>
  )
}

CartItem.propTypes = {
  item: PropTypes.object,
}

CartItem.defaultProps = {
  siteTitle: {},
}
export default inject("cart")(observer(CartItem))
